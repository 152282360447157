import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
export const getCoachList = createAsyncThunk(
  "coach/getCoachList",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInterceptors.get(`admin-coach-crud/`);
      return response;
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const coachSlice = createSlice({
  name: "coach",
  initialState: {
    coachData: {isLoading: false, data: [], isRejected: false},
  },
  reducers: {},
  extraReducers: {
    [getCoachList.pending]: (state) => {
      state.coachData.isLoading = true;
      state.coachData.isRejected = false;
    },
    [getCoachList.fulfilled]: (state, { payload }) => {
        state.coachData.isLoading = false;
        state.coachData.isRejected = false;
        state.coachData.data = payload.data
    },
    [getCoachList.rejected]: (state) => {
        state.coachData.isLoading = false;
        state.coachData.isRejected = true;
    },
  },
});
export default coachSlice.reducer;
