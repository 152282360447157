import React from "react";

export default function VoiceIcon({ className }) {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M17.4142 9.55425C17.4142 9.03675 16.9942 8.61675 16.4767 8.61675C15.9592 8.61675 15.5392 9.03675 15.5392 9.55425C15.5392 15.0938 12.138 17.6175 8.97673 17.6175C5.81548 17.6175 2.41423 15.0938 2.41423 9.55425C2.41423 9.03675 1.99423 8.61675 1.47673 8.61675C0.95923 8.61675 0.53923 9.03675 0.53923 9.55425C0.53323 9.68775 0.52948 9.84375 0.52948 10.0013C0.52948 12.666 1.51723 15.0997 3.14623 16.9567L3.13573 16.9448C4.39348 18.2843 6.10648 19.1835 8.02648 19.3958L8.06173 19.3987V21.5618H5.99923C5.48173 21.5618 5.06173 21.9818 5.06173 22.4993C5.06173 23.0168 5.48173 23.4368 5.99923 23.4368H11.9992C12.5167 23.4368 12.9367 23.0168 12.9367 22.4993C12.9367 21.9818 12.5167 21.5618 11.9992 21.5618H9.93673V19.425C13.7925 18.9442 17.4135 15.5775 17.4135 9.5535L17.4142 9.55425ZM8.99998 16.6875C12.0015 16.6845 14.4345 14.2515 14.4375 11.25V6C14.4375 2.997 12.003 0.5625 8.99998 0.5625C5.99698 0.5625 3.56248 2.997 3.56248 6V11.25C3.56548 14.2515 5.99848 16.6845 8.99998 16.6875ZM5.43748 6C5.43748 4.03275 7.03273 2.4375 8.99998 2.4375C10.9672 2.4375 12.5625 4.03275 12.5625 6V11.25C12.5625 13.2172 10.9672 14.8125 8.99998 14.8125C7.03273 14.8125 5.43748 13.2172 5.43748 11.25V6Z"
        fill="#6B7280"
        stroke-width="0.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
