import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
export const getGuardianList = createAsyncThunk(
  "guardian/getGuardianList",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInterceptors.get(`admin-guardian-crud/`);
      return response;
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const guardianSlice = createSlice({
  name: "guardian",
  initialState: {
    guardiansData: {isLoading: false, data: [], isRejected: false},
  },
  reducers: {},
  extraReducers: {
    [getGuardianList.pending]: (state) => {
      state.guardiansData.isLoading = true;
      state.guardiansData.isRejected = false;
    },
    [getGuardianList.fulfilled]: (state, { payload }) => {
        state.guardiansData.isLoading = false;
        state.guardiansData.isRejected = false;
        state.guardiansData.data = payload.data
    },
    [getGuardianList.rejected]: (state) => {
        state.guardiansData.isLoading = false;
        state.guardiansData.isRejected = true;
    },
  },
});
export default guardianSlice.reducer;
