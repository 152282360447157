import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { headerValue } from "../podcast/podcastSlice";
import { getCleanQueryString } from "../channel/channelSlice";

export const getSponsor = createAsyncThunk(
  "dashboard/getSponsor",
  async (_, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `sponsored-content/`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getSponsors = createAsyncThunk(
  "dashboard/getSponsors",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `sponsored-content/?${queryString}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getSponsorById = createAsyncThunk(
  "dashboard/getSponsorById",
  async (id, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `sponsored-content/?sponsor_content_uuid=${id}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateSponsor = createAsyncThunk(
  "dashboard/updateSponsor",
  async ({ reqBody, uuid }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.put(
        `sponsored-content/${uuid}/`,
        reqBody,
        headers
      );

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addSponsor = createAsyncThunk(
  "dashboard/addSponsor",
  async (reqBody, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.post(
        `sponsored-content/`,
        reqBody,
        headers
      );

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteSponsor = createAsyncThunk(
  "dashboard/deleteSponsor",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.delete(
        `sponsored-content/${uuid}/`,
        headers
      );

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const sponsorSlice = createSlice({
  name: "sponsor",
  initialState: {
    sponsor: { isLoading: false, data: [], isRejected: false },
    sponsors: { isLoading: false, data: [], isRejected: false },
    sponsorById: { isLoading: false, data: {}, isRejected: false },
    updateSponsor: { isLoading: false, data: [], isRejected: false },
    deleteSponsor: { isLoading: false, isRejected: false },
    sponsorDebounce: null,
    searchSponsor: "",
    page: 1,
    pageSize: { value: 10 },
  },
  reducers: {
    clearSponsor: (state, { payload }) => {
      state.pageSize = { value: 10 };
      state.page = 1;
      state.searchSponsor = "";
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSearchSponsor: (state, { payload }) => {
      state.page = 1;
      state.searchSponsor = payload;
    },
    updateSponsorDebounce: (state, { payload }) => {
      state.sponsorDebounce = payload;
    },
  },
  extraReducers: {
    [getSponsor.pending]: (state) => {
      state.sponsor.isLoading = true;
      state.sponsor.isRejected = false;
    },
    [getSponsor.fulfilled]: (state, { payload }) => {
      state.sponsor.isLoading = false;
      state.sponsor = { ...payload, data: payload?.data };
      state.sponsor.isRejected = false;
    },
    [getSponsor.rejected]: (state) => {
      state.sponsor.isLoading = false;
      state.sponsor.isRejected = false;
    },
    [getSponsors.pending]: (state) => {
      state.sponsor.isLoading = true;
      state.sponsor.isRejected = false;
    },
    [getSponsors.fulfilled]: (state, { payload }) => {
      state.sponsor.isLoading = false;
      state.sponsor = { ...payload, data: payload?.data };
      state.sponsor.isRejected = false;
    },
    [getSponsors.rejected]: (state) => {
      state.sponsor.isLoading = false;
      state.sponsor.isRejected = false;
    },
    [getSponsorById.pending]: (state) => {
      state.sponsorById.isLoading = true;
      state.sponsorById.isRejected = false;
    },
    [getSponsorById.fulfilled]: (state, { payload }) => {
      state.sponsorById.isLoading = false;
      state.sponsorById.data = payload.data;
      state.sponsorById.isRejected = false;
    },
    [getSponsorById.rejected]: (state) => {
      state.sponsorById.isLoading = false;
      state.sponsorById.isRejected = false;
    },
    [addSponsor.pending]: (state) => {
      state.updateSponsor.isLoading = true;
      state.updateSponsor.isRejected = false;
    },
    [addSponsor.fulfilled]: (state, { payload }) => {
      state.updateSponsor.isLoading = false;
      state.updateSponsor.isRejected = false;
    },
    [addSponsor.rejected]: (state) => {
      state.updateSponsor.isLoading = false;
      state.updateSponsor.isRejected = false;
    },
    [updateSponsor.pending]: (state) => {
      state.updateSponsor.isLoading = true;
      state.updateSponsor.isRejected = false;
    },
    [updateSponsor.fulfilled]: (state, { payload }) => {
      state.updateSponsor.isLoading = false;
      state.updateSponsor.data = payload?.data;
      state.updateSponsor.isRejected = false;
    },
    [updateSponsor.rejected]: (state) => {
      state.updateSponsor.isLoading = false;
      state.updateSponsor.isRejected = false;
    },
    [deleteSponsor.pending]: (state) => {
      state.deleteSponsor.isLoading = true;
      state.deleteSponsor.isRejected = false;
    },
    [deleteSponsor.fulfilled]: (state, action) => {
      state.deleteSponsor.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.sponsor.data = state.sponsor.data.filter(
          (sponsor) => sponsor?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deleteSponsor.isRejected = false;
    },
    [deleteSponsor.rejected]: (state) => {
      state.deleteSponsor.isLoading = false;
      state.deleteSponsor.isRejected = false;
    },
  },
});
export const {
  updateSponsorDebounce,
  setSearchSponsor,
  setPage,
  setPageSize,
  clearSponsor,
} = sponsorSlice.actions;
export default sponsorSlice.reducer;
