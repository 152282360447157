import { Popover } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DownArrowIcon from "../assets/Icons/DownArrowIcon";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import UserIcon from "../assets/Icons/UserIcon";
import LogoutIcon from "./../assets/Icons/LogoutIcon";

export default function DashboardNavigation() {
  const navigate = useNavigate();
  const { sidebarExpanded } = useSelector((state) => ({
    sidebarExpanded: state.dashboard.sidebarExpanded,
  }));
  let user = JSON.parse(localStorage.getItem("user"))?.user_data;

  const textStyle =
    "m-3 font-semibold cursor-pointer text-black hover:text-blue-500 w-full dark:text-dark-100 group-hover:text-blue-500 group-focus:text-blue-500 text-base";

  const content = () => {
    return (
      <div className="w-full">
        <div className="flex items-center text-base hover:bg-[#E6F5FF] px-2">
          <div className="w-[1rem]">
            <UserIcon className="menu-icon" />
          </div>
          <div
            onClick={() => {
              navigate("/user-profile/edit");
            }}
            className={textStyle}
          >
            {"Profile"}
          </div>
        </div>

        <div className="flex items-center text-base hover:bg-[#E6F5FF] px-2">
          <div className="w-[1rem]">
            <LogoutIcon className="menu-icon" />
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/login");
            }}
            className={`${textStyle}`}
          >
            {"Logout"}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className={`sticky flex items-center justify-between bg-white border-b h-[90px] px-6 md:z-10 z-10 top-0  ${
        sidebarExpanded ? "md:ml-64 right-0" : "md:ml-12  right-0"
      }`}
    >
      <div className="relative max-w-sm md:max-w-md">
        <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none"></div>
      </div>
      <div className="flex items-center space-x-4 sm:space-x-8 md:space-x-12"></div>
      <div className="flex items-center space-x-4 sm:space-x-8 md:space-x-12"></div>
      {/* add username to the popover title property */}
      <Popover
        content={content}
        overlayStyle={{
          width: "10vw",
        }}
      >
        <div className="cursor-pointer flex items-center space-x-4 justify-end">
          <div className="rounded-[16px,16px,16px,0px]">
            <div className="flex justify-start h-full w-full">
              <Avatar
                size={55}
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                src={user?.profile_image}
                icon={<UserOutlined />}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-md font-semibold  text-black leading-[18px]">
              {`${user?.first_name} ${user?.last_name}`}
            </p>
            <p className="text-[12px] leading-3 font-normal text-[rgba(0,0,0,0.64)]">
              {`${user?.username}`}
            </p>
          </div>
          <div onClick={() => {}} className="p-2 cursor-pointer -rotate-90">
            <DownArrowIcon />
          </div>
        </div>
      </Popover>
    </div>
  );
}
