import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
export const getAthleteList = createAsyncThunk(
    "athleteList/getAthleteList",
    async (_, thunkAPI) => {
        try {
            const response = await axiosInterceptors.get(`admin-athlete-crud/`);
            return response;
        } catch (e) {
            throw thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getAthleteById = createAsyncThunk(
    "athleteList/getAthleteById",
    async ({ uuid }, thunkAPI) => {
        try {
            const response = await axiosInterceptors.get(`admin-athlete-crud/${uuid}/`);
            return response;
        } catch (e) {
            throw thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const athleteSlice = createSlice({
    name: "athlete",
    initialState: {
        athleteData: { isLoading: false, data: [], isRejected: false },
        athleteById: { isLoading: false, data: [], isRejected: false }
    },
    reducers: {},
    extraReducers: {
        [getAthleteList.pending]: (state) => {
            state.athleteData.isLoading = true;
            state.athleteData.isRejected = false;
        },
        [getAthleteList.fulfilled]: (state, { payload }) => {
            state.athleteData.isLoading = false;
            state.athleteData.isRejected = false;
            state.athleteData.data = payload.data
        },
        [getAthleteList.rejected]: (state) => {
            state.athleteData.isLoading = false;
            state.athleteData.isRejected = true;
        },
        [getAthleteById.pending]: (state) => {
            state.athleteById.isLoading = true;
            state.athleteById.isRejected = false;
        },
        [getAthleteById.fulfilled]: (state, { payload }) => {
            state.athleteById.isLoading = false;
            state.athleteById.isRejected = false;
            state.athleteById.data = payload.data
        },
        [getAthleteById.rejected]: (state) => {
            state.athleteById.isLoading = false;
            state.athleteById.isRejected = true;
        },

    },
});
export default athleteSlice.reducer;
