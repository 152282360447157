import React from "react";

export default function ChevronDown({ className }) {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M5.00002 6.50002L0.757019 2.25702L2.17202 0.843018L5.00002 3.67202L7.82802 0.843018L9.24302 2.25702L5.00002 6.50002Z"
        fill="currentColor"
      />
    </svg>
  );
}
