import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { getCleanQueryString } from "./../generateAccessCode/generateAccessCodeSlice";

const initialState = {
  usersData: { isLoading: false, data: [], isRejected: false },
  searchUser: "",
  userDebounce: null,
  page: 1,
  pageSize: { value: 10 },
  adminDetail: { isLoading: false, isRejected: false, data: null },
};
export const getAdminDashboard = createAsyncThunk(
  "home/getAdminDashboard",
  async (params, thunkAPI) => {
    try {
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `admin-dashboard/?${queryString}`
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  extraReducers: {
    [getAdminDashboard.pending]: (state) => {
      state.usersData.isLoading = true;
      state.usersData.isRejected = false;
    },
    [getAdminDashboard.fulfilled]: (state, { payload }) => {
      state.usersData.isLoading = false;
      state.usersData.data = payload?.data;
      state.usersData.isRejected = false;
    },
    [getAdminDashboard.rejected]: (state) => {
      state.usersData.isLoading = false;
      state.usersData.isRejected = false;
    },
  },
});
export const { toggleSidebar } = homeSlice.actions;
export default homeSlice.reducer;
