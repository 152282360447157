import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";

export const getEmailCategory = createAsyncThunk(
  "dashboard/getEmailCategory",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInterceptors.get(`email-category/`);

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateEmailCategory = createAsyncThunk(
  "dashboard/updateEmailCategory",
  async ({ category_name, uuid }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.put(`email-category/${uuid}/`, {
        category_name,
      });

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addEmailCategory = createAsyncThunk(
  "dashboard/addEmailCategory",
  async ({ category_name }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`email-category/`, {
        category_name,
      });

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteEmailCategory = createAsyncThunk(
  "dashboard/deleteEmailCategory",
  async ({ uuid }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.delete(
        `email-category/${uuid}/`
      );

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const emailCategorySlice = createSlice({
  name: "emailCategory",
  initialState: {
    emailCategory: { isLoading: false, data: [], isRejected: false },
    updateCategory: { isLoading: false, data: [], isRejected: false },
    deleteCategory: { isLoading: false, isRejected: false },
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
  },
  extraReducers: {
    [getEmailCategory.pending]: (state) => {
      state.emailCategory.isLoading = true;
      state.emailCategory.isRejected = false;
    },
    [getEmailCategory.fulfilled]: (state, { payload }) => {
      state.emailCategory.isLoading = false;
      state.emailCategory.data = payload?.data;
      state.emailCategory.isRejected = false;
    },
    [getEmailCategory.rejected]: (state) => {
      state.emailCategory.isLoading = false;
      state.emailCategory.isRejected = false;
    },
    [addEmailCategory.pending]: (state) => {
      state.updateCategory.isLoading = true;
      state.updateCategory.isRejected = false;
    },
    [addEmailCategory.fulfilled]: (state, { payload }) => {
      state.updateCategory.isLoading = false;
      state.emailCategory.data = [payload.data, ...state.emailCategory.data];
      state.updateCategory.isRejected = false;
    },
    [addEmailCategory.rejected]: (state) => {
      state.updateCategory.isLoading = false;
      state.updateCategory.isRejected = false;
    },
    [updateEmailCategory.pending]: (state) => {
      state.updateCategory.isLoading = true;
      state.updateCategory.isRejected = false;
    },
    [updateEmailCategory.fulfilled]: (state, { payload }) => {
      state.updateCategory.isLoading = false;
      state.updateCategory.data = payload?.data;
      const findIndex = state.emailCategory.data.findIndex(
        (data) => data?.uuid === payload?.data?.uuid
      );
      if (findIndex >= 0) {
        state.emailCategory.data[findIndex].category_name =
          payload?.data?.category_name;
      }
      state.updateCategory.isRejected = false;
    },
    [updateEmailCategory.rejected]: (state) => {
      state.updateCategory.isLoading = false;
      state.updateCategory.isRejected = false;
    },
    [deleteEmailCategory.pending]: (state) => {
      state.deleteCategory.isLoading = true;
      state.deleteCategory.isRejected = false;
    },
    [deleteEmailCategory.fulfilled]: (state, action) => {
      state.deleteCategory.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.emailCategory.data = state.emailCategory.data.filter(
          (category) => category?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deleteCategory.isRejected = false;
    },
    [deleteEmailCategory.rejected]: (state) => {
      state.deleteCategory.isLoading = false;
      state.deleteCategory.isRejected = false;
    },
  },
});
export const { toggleSidebar } = emailCategorySlice.actions;
export default emailCategorySlice.reducer;
