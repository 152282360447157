import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { headerValue } from "../podcast/podcastSlice";
import { axiosInterceptors } from "../../utils/api";

export const getAllSports = createAsyncThunk("sports", async (thunkAPI) => {
  try {
    let headers = headerValue();
    const response = await axiosInterceptors.get(`/sports/`, headers);
    return response;
  } catch (e) {
    thunkAPI.rejectWithValue(e.response.data);
  }
});

export const getAllClasses = createAsyncThunk("classes", async (thunkAPI) => {
  try {
    let headers = headerValue();
    const response = await axiosInterceptors.get(`/classes/`, headers);
    return response;
  } catch (e) {
    thunkAPI.rejectWithValue(e.response.data);
  }
});

export const getAllSportsClassesSlice = createSlice({
  name: "sports",
  initialState: {
    sports: { isLoading: false, data: [], isRejectd: false },
    classes: { isLoading: false, data: [], isRejectd: false },
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
  },
  extraReducers: {
    [getAllSports.pending]: (state) => {
      state.sports.isLoading = true;
      state.sports.isRejectd = false;
    },
    [getAllSports.fulfilled]: (state, { payload }) => {
      state.sports.isLoading = false;
      state.sports.data = payload?.data;
      state.sports.isRejectd = false;
    },
    [getAllSports.rejected]: (state) => {
      state.sports.isLoading = false;
      state.sports.isRejectd = false;
    },
    [getAllClasses.pending]: (state) => {
      state.classes.isLoading = true;
      state.classes.isRejectd = false;
    },
    [getAllClasses.fulfilled]: (state, { payload }) => {
      state.classes.isLoading = false;
      state.classes.data = payload?.data;
      state.classes.isRejectd = false;
    },
    [getAllClasses.rejected]: (state) => {
      state.classes.isLoading = false;
      state.classes.isRejectd = true;
    },
  },
});

export const { toggleSidebar } = getAllSportsClassesSlice.actions;
export default getAllSportsClassesSlice.reducer;
