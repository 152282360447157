import React from "react";

export default function EmailCampIcon({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <g clip-path="url(#clip0_346_1864)">
        <path
          d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L7.5287 5.48405"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M16.3113 7.17147L12.1831 10.1647L10.0191 8.57165L11.6004 3.83563L16.3113 7.17147Z"
          stroke-width="1.5"
        />
        <path
          d="M15.9025 2.95791C16.0231 2.92942 16.1341 2.85809 16.2106 2.74852L16.7837 1.92803C16.949 1.69134 16.8911 1.36548 16.6545 1.20028C16.4178 1.03504 16.0919 1.093 15.9268 1.32949L15.3537 2.14997C15.1884 2.38667 15.2463 2.71253 15.4829 2.87773C15.6099 2.96647 15.7627 2.99094 15.9025 2.95791Z"
          fill="#6B7280"
        />
        <path
          d="M17.4811 4.21011L16.5129 4.46345C16.2337 4.53654 16.0665 4.82213 16.1395 5.10128C16.2032 5.3445 16.4279 5.50269 16.6694 5.49125C16.7051 5.48956 16.7414 5.48398 16.7774 5.47462L17.7456 5.22129C18.0248 5.1482 18.1919 4.86253 18.1189 4.58346C18.046 4.30399 17.7604 4.13656 17.4811 4.21011Z"
          fill="#6B7280"
        />
        <path
          d="M13.4277 3.21062C13.5007 3.19214 13.5662 3.15908 13.6218 3.11543C13.7794 2.99164 13.8582 2.78226 13.8057 2.5755L13.5594 1.60546C13.4883 1.32558 13.204 1.15638 12.9243 1.22746C12.6443 1.29847 12.4752 1.58281 12.5463 1.86263L12.7925 2.83267C12.8636 3.11243 13.1479 3.28164 13.4277 3.21062Z"
          fill="#6B7280"
        />
      </g>
      <defs>
        <clipPath id="clip0_346_1864">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
