import React from "react";
import { lazy } from "react";

const Login = lazy(() => import("./../Pages/Login"));
const NoMatch = lazy(() => import("./../Pages/NoMatch"));
const Podcast = lazy(() => import("./../Pages/Podcast"));
const Episode = lazy(() => import("./../Pages/Episode"));
const PodcastAddEdit = lazy(() => import("./../Pages/PodcastAddEdit"));
const Channel = lazy(() => import("./../Pages/Channel"));
const ChannelAddEdit = lazy(() => import("./../Pages/ChannelAddEdit"));
const UserProfile = lazy(() => import("./../Pages/userProfile/UserProfile"));
const EpisodeAddEdit = lazy(() => import("./../Pages/EpisodeAddEdit"));
const BugReport = lazy(() => import("./../Pages/BugReport"));
const BugReportView = lazy(() => import("./../Pages/BugReportView"));
const EmailCampaign = lazy(() => import("./../Pages/EmailCampaign"));
const UserManagement = lazy(() =>
  import("./../Pages/userManagement/UserManagement")
);
const UserManagementView = lazy(() =>
  import("./../Pages/userManagement/UserManagementView")
);
const AccessAccessCode = lazy(() => import("./../Pages/GenerateAccessCode"));
const AccessCodeList = lazy(() => import("./../Pages/AccessCodeList"));
const ReportPost = lazy(() => import("./../Pages/ReportPost"));
const ReportPostView = lazy(() => import("./../Pages/ReportPostView"));
const SponsorContentList = lazy(() => import("../Pages/SponsorContentList"));
const SponsorContentAddEdit = lazy(() =>
  import("./../Pages/SponsorContentAddEdit")
);
const SponsorList = lazy(() => import("./../Pages/SponsorList"));
const SponsorAddEdit = lazy(() => import("./../Pages/SponsorAddEdit"));
const Dashboard = lazy(() => import("./../Pages/Dashboard"));
const EpisodeRSSAdd = lazy(() => import("./../Pages/EpisodeRSSAdd"));

/* add your route here
 * ex.
 * { path: RoutePath.home, element: <Home /> },
 */

export const ListRouteElements = [
  { path: "/login", element: <Login /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/user-profile/:profile", element: <UserProfile /> },
  { path: "/channel", element: <Channel /> },
  { path: "/channel/add", element: <ChannelAddEdit /> },
  { path: "/channel/edit/:id", element: <ChannelAddEdit /> },
  { path: "/podcast", element: <Podcast /> },
  { path: "/podcast/add", element: <PodcastAddEdit /> },
  { path: "/podcast/edit/:id", element: <PodcastAddEdit /> },
  { path: "/episode", element: <Episode /> },
  { path: "/episode/add", element: <EpisodeAddEdit /> },
  { path: "/episode/edit/:id", element: <EpisodeAddEdit /> },
  { path: "/episode/rss/add", element: <EpisodeRSSAdd /> },
  { path: "/report-bug", element: <BugReport /> },
  { path: "/report-bug/:id", element: <BugReportView /> },
  { path: "/user-management", element: <UserManagement /> },
  {
    path: "/user-management/view/:uuid",
    element: <UserManagementView />,
  },
  {
    path: "/user-management/add",
    element: <UserManagementView />,
  },
  { path: "/invite-code", element: <AccessCodeList /> },
  {
    path: "/generate-access-code",
    element: <AccessAccessCode />,
  },
  { path: "/email-campaign", element: <EmailCampaign /> },
  { path: "/report-post", element: <ReportPost /> },
  { path: "/report-post/:id", element: <ReportPostView /> },
  { path: "/sponsor", element: <SponsorList /> },
  { path: "/sponsor/add", element: <SponsorAddEdit /> },
  { path: "/sponsor/edit/:id", element: <SponsorAddEdit /> },
  { path: "/sponsor-content", element: <SponsorContentList /> },
  { path: "/sponsor-content/add", element: <SponsorContentAddEdit /> },
  { path: "/sponsor-content/edit/:id", element: <SponsorContentAddEdit /> },
  { path: "*", element: <NoMatch /> },
];
