import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { headerValue } from "./../podcast/podcastSlice";

export const getUserProfile = createAsyncThunk(
  "dashboard/getUserProfile",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `user-personal-information/`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "dashboard/updateUserProfile",
  async (reqBody, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.post(
        `user-profile/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    userProfile: { isLoading: false, data: {}, isRejected: false },
    updateUserProfile: { isLoading: false, data: {}, isRejected: false },
  },
  // reducers: {
  //   clearUserProfile: (state, { payload }) => {
  //     state.pageSize = { value: 10 };
  //     state.page = 1;
  //     state.searchUserProfile = "";
  //   },
  //   setPageSize: (state, { payload }) => {
  //     state.pageSize = payload;
  //   },
  //   setPage: (state, { payload }) => {
  //     state.page = payload;
  //   },
  //   setSearchUserProfile: (state, { payload }) => {
  //     state.page = 1;
  //     state.searchUserProfile = payload;
  //   },
  //   updateUserProfileDebounce: (state, { payload }) => {
  //     state.userProfileDebounce = payload;
  //   },
  // },
  extraReducers: {
    [getUserProfile.pending]: (state) => {
      state.userProfile.isLoading = true;
      state.userProfile.isRejected = false;
    },
    [getUserProfile.fulfilled]: (state, { payload }) => {
      state.userProfile.isLoading = false;
      state.userProfile = { ...payload, data: payload?.data };
      state.userProfile.isRejected = false;
    },
    [getUserProfile.rejected]: (state) => {
      state.userProfile.isLoading = false;
      state.userProfile.isRejected = false;
    },
    [updateUserProfile.pending]: (state) => {
      state.updateUserProfile.isLoading = true;
      state.updateUserProfile.isRejected = false;
    },
    [updateUserProfile.fulfilled]: (state, { payload }) => {
      state.updateUserProfile.isLoading = false;
      state.updateUserProfile.data = payload?.data;
      state.updateUserProfile.isRejected = false;
    },
    [updateUserProfile.rejected]: (state) => {
      state.updateUserProfile.isLoading = false;
      state.updateUserProfile.isRejected = false;
    },
  },
});
export const {
  clearUserProfile,
  updateUserProfileDebounce,
  setSearchUserProfile,
  setPage,
  setPageSize,
} = userProfileSlice.actions;
export default userProfileSlice.reducer;
