import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import DashboardNavigation from "./DashboardNavigation";
import DashboardSidebar from "./DashboardSidebar";

export default function DashboardLayout({ children }) {
  const { sidebarExpanded } = useSelector((state) => ({
    sidebarExpanded: state.dashboard.sidebarExpanded,
  }));
  const { pathname } = useLocation();
  const userData = JSON.parse(localStorage.getItem("user"));
  if (pathname?.includes("login")) {
    return <div>{children}</div>;
  }

  if (userData?.access_token) {
    return (
      <div className="min-w-full min-h-screen bg-gray-300 overflow-auto">
        <main className="">
          <DashboardNavigation />
          <DashboardSidebar />
          <div
            className={`${sidebarExpanded ? "md:ml-64 pl-6 pt-6 " : "md:ml-12 p-4"
              } h-[calc(100vh-90px)] overflow-x-hidden overflow-y-scroll`}
          >
            {children}
          </div>
        </main>
      </div>
    );
  } else {
    return <Navigate to="/login" />;
  }
}
