import React from "react";

export default function UserManagementIcon({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M5 20V19C5 16.2386 7.23858 14 10 14M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip0_346_1848)">
        <path
          d="M15 18C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16C14.4477 16 14 16.4477 14 17C14 17.5523 14.4477 18 15 18Z"
          stroke-miterlimit="10"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.6667 17.2933V16.7066C11.6667 16.36 11.95 16.0733 12.3 16.0733C12.9034 16.0733 13.15 15.6467 12.8467 15.1233C12.6734 14.8233 12.7767 14.4333 13.08 14.26L13.6567 13.93C13.92 13.7733 14.26 13.8667 14.4167 14.13L14.4534 14.1933C14.7534 14.7167 15.2467 14.7167 15.55 14.1933L15.5867 14.13C15.7434 13.8667 16.0834 13.7733 16.3467 13.93L16.9234 14.26C17.2267 14.4333 17.33 14.8233 17.1567 15.1233C16.8534 15.6467 17.1 16.0733 17.7034 16.0733C18.05 16.0733 18.3367 16.3566 18.3367 16.7066V17.2933C18.3367 17.64 18.0534 17.9266 17.7034 17.9266C17.1 17.9266 16.8534 18.3533 17.1567 18.8766C17.33 19.18 17.2267 19.5666 16.9234 19.74L16.3467 20.07C16.0834 20.2266 15.7434 20.1333 15.5867 19.87L15.55 19.8066C15.25 19.2833 14.7567 19.2833 14.4534 19.8066L14.4167 19.87C14.26 20.1333 13.92 20.2266 13.6567 20.07L13.08 19.74C12.7767 19.5666 12.6734 19.1766 12.8467 18.8766C13.15 18.3533 12.9034 17.9266 12.3 17.9266C11.95 17.9266 11.6667 17.64 11.6667 17.2933Z"
          stroke-miterlimit="10"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_346_1848">
          <rect
            width="8"
            height="8"
            fill="white"
            transform="translate(11 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
