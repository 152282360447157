import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`admin-login/`, {
        email,
        password,
      });
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    email: "",
    password: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    userData: JSON.parse(localStorage.getItem("user-data")),
    redirectLogin: {
      isLoading: false,
      data: null,
      isRejected: false,
    },
  },
  reducers: {
    clearRedirectLoginData: (state) => {
      state.redirectLogin = {
        isLoading: false,
        data: null,
        isRejected: false,
      };
    },
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      if (!!payload?.data) {
        localStorage.setItem("user", JSON.stringify(payload.data));
      } else {
        state.isFetching = false;
      }
    },
    [loginUser.rejected]: (state) => {
      state.isError = true;
      state.isFetching = false;
    },
  },
});
export const { clearState, clearRedirectLoginData } = authSlice.actions;
