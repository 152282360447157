import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { headerValue } from "../podcast/podcastSlice";
import { getCleanQueryString } from "../channel/channelSlice";

export const getSponsorData = createAsyncThunk(
  "dashboard/getSponsorDatas",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `hype-cast-sponsor-crud/?${queryString}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getSponsorDataById = createAsyncThunk(
  "dashboard/getSponsorDataById",
  async (id, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `hype-cast-sponsor-crud/?sponsor_uuid=${id}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateSponsorData = createAsyncThunk(
  "dashboard/updateSponsorData",
  async ({ reqBody, uuid }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.put(
        `hype-cast-sponsor-crud/${uuid}/`,
        reqBody,
        headers
      );

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addSponsorData = createAsyncThunk(
  "dashboard/addSponsorData",
  async (reqBody, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.post(
        `hype-cast-sponsor-crud/`,
        reqBody,
        headers
      );

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteSponsorData = createAsyncThunk(
  "dashboard/deleteSponsorData",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.delete(
        `hype-cast-sponsor-crud/${uuid}/`,
        headers
      );

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const sponsorDataSlice = createSlice({
  name: "sponsorData",
  initialState: {
    sponsorData: { isLoading: false, data: [], isRejected: false },
    sponsorDataById: { isLoading: false, data: {}, isRejected: false },
    updateSponsorData: { isLoading: false, data: [], isRejected: false },
    deleteSponsorData: { isLoading: false, isRejected: false },
    sponsorDataDebounce: null,
    searchSponsorData: "",
    page: 1,
    pageSize: { value: 10 },
  },
  reducers: {
    clearSponsorData: (state, { payload }) => {
      state.pageSize = { value: 10 };
      state.page = 1;
      state.searchSponsorData = "";
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSearchSponsorData: (state, { payload }) => {
      state.page = 1;
      state.searchSponsorData = payload;
    },
    updateSponsorDataDebounce: (state, { payload }) => {
      state.sponsorDataDebounce = payload;
    },
  },
  extraReducers: {
    [getSponsorData.pending]: (state) => {
      state.sponsorData.isLoading = true;
      state.sponsorData.isRejected = false;
    },
    [getSponsorData.fulfilled]: (state, { payload }) => {
      state.sponsorData.isLoading = false;
      state.sponsorData = { ...payload, data: payload?.data };
      state.sponsorData.isRejected = false;
    },
    [getSponsorData.rejected]: (state) => {
      state.sponsorData.isLoading = false;
      state.sponsorData.isRejected = false;
    },
    [getSponsorDataById.pending]: (state) => {
      state.sponsorDataById.isLoading = true;
      state.sponsorDataById.isRejected = false;
    },
    [getSponsorDataById.fulfilled]: (state, { payload }) => {
      state.sponsorDataById.isLoading = false;
      state.sponsorDataById.data = payload.data;
      state.sponsorDataById.isRejected = false;
    },
    [getSponsorDataById.rejected]: (state) => {
      state.sponsorDataById.isLoading = false;
      state.sponsorDataById.isRejected = false;
    },
    [addSponsorData.pending]: (state) => {
      state.updateSponsorData.isLoading = true;
      state.updateSponsorData.isRejected = false;
    },
    [addSponsorData.fulfilled]: (state, { payload }) => {
      state.updateSponsorData.isLoading = false;
      state.updateSponsorData.isRejected = false;
    },
    [addSponsorData.rejected]: (state) => {
      state.updateSponsorData.isLoading = false;
      state.updateSponsorData.isRejected = false;
    },
    [updateSponsorData.pending]: (state) => {
      state.updateSponsorData.isLoading = true;
      state.updateSponsorData.isRejected = false;
    },
    [updateSponsorData.fulfilled]: (state, { payload }) => {
      state.updateSponsorData.isLoading = false;
      state.updateSponsorData.data = payload?.data;
      state.updateSponsorData.isRejected = false;
    },
    [updateSponsorData.rejected]: (state) => {
      state.updateSponsorData.isLoading = false;
      state.updateSponsorData.isRejected = false;
    },
    [deleteSponsorData.pending]: (state) => {
      state.deleteSponsorData.isLoading = true;
      state.deleteSponsorData.isRejected = false;
    },
    [deleteSponsorData.fulfilled]: (state, action) => {
      state.deleteSponsorData.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.sponsorData.data = state.sponsorData.data.filter(
          (sponsorData) => sponsorData?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deleteSponsorData.isRejected = false;
    },
    [deleteSponsorData.rejected]: (state) => {
      state.deleteSponsorData.isLoading = false;
      state.deleteSponsorData.isRejected = false;
    },
  },
});
export const {
  updateSponsorDataDebounce,
  setSearchSponsorData,
  setPage,
  setPageSize,
  clearSponsorData,
} = sponsorDataSlice.actions;
export default sponsorDataSlice.reducer;
