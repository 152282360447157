import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";

export const getCleanQueryString = (params) => {
  let { fromDate, ...query } = params;
  return Object.keys(query)
    .filter((key) => query[key] !== "")
    .map((keyName) => keyName + "=" + query[keyName])
    .join("&");
};

export const getAccessCodes = createAsyncThunk(
  "accessCode/getAccessCodes",
  async (params, thunkAPI) => {
    try {
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `generate-access-code/?list_type=all&${queryString}`
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const CreateAccessCode = createAsyncThunk(
  "accessCode/createAccessCode",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`generate-access-code/`);
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateAccessCode = createAsyncThunk(
  "accessCode/updateAccessCode",
  async ({ uuid, reqBody }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.put(
        `generate-access-code/${uuid}/`,
        reqBody
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response);
    }
  }
);
export const deleteAccessCode = createAsyncThunk(
  "accessCode/deleteAccessCode",
  async (uuid, thunkAPI) => {
    try {
      const response = await axiosInterceptors.delete(
        `generate-access-code/${uuid}/`
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const generateAccessCodeSlice = createSlice({
  name: "accessCode",
  initialState: {
    accessCodeData: { isLoading: false, data: [], isRejected: false },
    CreateAccessCodes: { isLoading: false, data: {}, isRejected: false },
    updateAccessCode: { isLoading: false, data: [], isRejected: false },
    deleteAccessCodes: { isLoading: false, isRejected: false },
    searchAccessCode: "",
    AccessCodeDebounce: null,
    page: 1,
    pageSize: { value: 10 },
  },
  reducers: {
    clearAccessCode: (state, { payload }) => {
      state.pageSize = { value: 10 };
      state.page = 1;
      state.searchAccessCode = "";
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSearchAccessCode: (state, { payload }) => {
      state.page = 1;
      state.searchAccessCode = payload;
    },
    updateAccessCodeDebounce: (state, { payload }) => {
      state.AccessCodeDebounce = payload;
    },
  },
  extraReducers: {
    [getAccessCodes.pending]: (state) => {
      state.accessCodeData.isLoading = true;
      state.accessCodeData.isRejected = false;
    },
    [getAccessCodes.fulfilled]: (state, { payload }) => {
      state.accessCodeData.isLoading = false;
      state.accessCodeData = { ...payload, data: payload?.data };
      state.accessCodeData.isRejected = false;
    },
    [getAccessCodes.rejected]: (state) => {
      state.accessCodeData.isLoading = false;
      state.accessCodeData.isRejected = false;
    },
    [updateAccessCode.pending]: (state) => {
      state.updateAccessCode.isLoading = true;
      state.updateAccessCode.isRejected = false;
    },
    [updateAccessCode.fulfilled]: (state, { payload }) => {
      state.updateAccessCode.isLoading = false;
      state.updateAccessCode.data = { ...payload, data: payload?.data };
      state.updateAccessCode.isRejected = false;
    },
    [updateAccessCode.rejected]: (state) => {
      state.updateAccessCode.isLoading = false;
      state.updateAccessCode.isRejected = false;
    },
    [CreateAccessCode.pending]: (state) => {
      state.CreateAccessCodes.isLoading = true;
      state.CreateAccessCodes.isRejected = false;
    },
    [CreateAccessCode.fulfilled]: (state, { payload }) => {
      state.CreateAccessCodes.isLoading = false;
      state.CreateAccessCodes.data = payload?.data;
      state.CreateAccessCodes.isRejected = false;
    },
    [CreateAccessCode.rejected]: (state) => {
      state.CreateAccessCodes.isLoading = false;
      state.CreateAccessCodes.isRejected = false;
    },
    [deleteAccessCode.pending]: (state) => {
      state.deleteAccessCodes.isLoading = true;
      state.deleteAccessCodes.isRejected = false;
    },
    [deleteAccessCode.fulfilled]: (state, action) => {
      state.deleteAccessCodes.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.channel.data = state.channel.data.filter(
          (category) => category?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deleteAccessCodes.isRejected = false;
    },
    [deleteAccessCode.rejected]: (state) => {
      state.deleteAccessCodes.isLoading = false;
      state.deleteAccessCodes.isRejected = false;
    },
  },
});
export const {
  clearAccessCode,
  updateAccessCodeDebounce,
  setSearchAccess,
  searchAccessCode,
  setPage,
  setPageSize,
} = generateAccessCodeSlice.actions;
export default generateAccessCodeSlice.reducer;
