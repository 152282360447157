import axios from "axios";
import { toast } from "react-hot-toast";

// const stagingBucketURL = "https://hype-bucket.s3.amazonaws.com/";
// const stagingServerURL = "https://backend.hypeathletenetwork.com/api";

const liveBucketURL =
  "https://hype-developer-staging-16-03-23.s3.amazonaws.com/";
const liveServerURL = "https://developer.hypeathletenetwork.com/api";

export const tokenValue =
  "token " + JSON.parse(localStorage.getItem("user"))?.token;

export const URLS3 = liveBucketURL;
const REACT_APP_API_URL = liveServerURL;

const axiosInterceptors = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 35000,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer",
  },
});

const showToastMethods = ["put", "post", "delete"];
axiosInterceptors.interceptors.request.use(function (config) {
  let tokenVal = config.Authorization;
  axios.defaults.timeout = 35000;
  const token =
    "Bearer " + JSON.parse(localStorage.getItem("user"))?.access_token;
  config.headers.Authorization = tokenVal ? tokenVal : token;
  return config;
});

axiosInterceptors.interceptors.response.use(
  function onSuccess(response) {
    if (
      showToastMethods?.includes(response?.config?.method) &&
      response?.data?.message
    )
      toast.success(response?.data?.message);
    return response.data;
  },
  function onError(error) {
    if (
      error.response.data.status_code === 401 &&
      !window.location.toString().includes("login")
    ) {
      localStorage.clear();
      window.location.assign("/login");
    } else {
      if (!error?.response) {
        // return window.location.assign('/something-went-wrong')
      }
      if (error && error.response && error.response.status) {
        if (error.response.data.status_code === 404) {
          return;
        }
        if (
          error?.response?.data?.message !== "Requested profiles was not found."
        )
          var msgs = error?.response?.data?.message.indexOf(":");
        var message = error?.response?.data?.message.substring(msgs + 1);
        toast.error(message || "Error");
      }
    }

    return Promise.reject(error);
  }
);

export { axiosInterceptors };
