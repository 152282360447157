import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";

export const getEmailMarketing = createAsyncThunk(
  "dashboard/getEmailMarketing",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInterceptors.get(`email-marketing/`);

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getEmailCategory = createAsyncThunk(
  "dashboard/getEmailCategory",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInterceptors.get(`email-category/`);

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const createEmailMarketing = createAsyncThunk(
  "dashboard/createEmailMarketing",
  async ({ email, category }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`email-marketing/`, {
        email,
        // email_category: "nike",
      });

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateEmailMarketing = createAsyncThunk(
  "dashboard/updateEmailMarketing",
  async ({ email, uuid, category }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.put(`email-marketing/${uuid}/`, {
        email,
        email_category: category,
      });

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteUserRecord = createAsyncThunk(
  "dashboard/deleteUserRecord",
  async ({ uuid }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.delete(
        `email-marketing/${uuid}/`
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getStatesData = createAsyncThunk(
  "dashboard/getStatesData",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInterceptors.get(`states/`);

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    sidebarExpanded: true,
    usersData: { isLoading: false, data: [], isRejected: false },
    updateUser: { isLoading: false, data: [], isRejected: false },
    emailCategory: { isLoading: false, data: [], isRejected: false },
    deleteUserData: { isLoading: false, isRejected: false },
    stateData: []
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
  },
  extraReducers: {
    [getEmailMarketing.pending]: (state) => {
      state.usersData.isLoading = true;
      state.usersData.isRejected = false;
    },
    [getEmailMarketing.fulfilled]: (state, { payload }) => {
      state.usersData.isLoading = false;
      state.usersData.data = payload?.data;
      state.usersData.isRejected = false;
    },
    [getEmailMarketing.rejected]: (state) => {
      state.usersData.isLoading = false;
      state.usersData.isRejected = false;
    },
    [deleteUserRecord.pending]: (state) => {
      state.deleteUserData.isLoading = true;
      state.deleteUserData.isRejected = false;
    },
    [deleteUserRecord.fulfilled]: (state, action) => {
      state.deleteUserData.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.usersData.data = state.usersData.data.filter(
          (user) => user?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deleteUserData.isRejected = false;
    },
    [deleteUserRecord.rejected]: (state) => {
      state.deleteUserData.isLoading = false;
      state.deleteUserData.isRejected = false;
    },
    [updateEmailMarketing.pending]: (state) => {
      state.updateUser.isLoading = true;
      state.updateUser.isRejected = false;
    },
    [updateEmailMarketing.fulfilled]: (state, { payload }) => {
      state.updateUser.isLoading = false;
      state.updateUser.data = payload?.data;
      const findIndex = state.usersData.data.findIndex(
        (data) => data?.uuid === payload?.data?.uuid
      );
      if (findIndex >= 0) {
        state.usersData.data[findIndex].email = payload?.data?.email;
      }
      state.updateUser.isRejected = false;
    },
    [updateEmailMarketing.rejected]: (state) => {
      state.updateUser.isLoading = false;
      state.updateUser.isRejected = false;
    },
    [createEmailMarketing.pending]: (state) => {
      state.updateUser.isLoading = true;
      state.updateUser.isRejected = false;
    },
    [createEmailMarketing.fulfilled]: (state, { payload }) => {
      state.updateUser.isLoading = false;
      state.updateUser.data = payload?.data;
      state.usersData.data = [payload.data, ...state.usersData.data];
      state.updateUser.isRejected = false;
    },
    [createEmailMarketing.rejected]: (state) => {
      state.updateUser.isLoading = false;
      state.updateUser.isRejected = false;
    },
    [getEmailCategory.pending]: (state) => {
      state.emailCategory.isLoading = true;
      state.emailCategory.isRejected = false;
    },
    [getEmailCategory.fulfilled]: (state, { payload }) => {
      state.emailCategory.isLoading = false;
      state.emailCategory.data = payload?.data;
      state.emailCategory.isRejected = false;
    },
    [getEmailCategory.rejected]: (state) => {
      state.emailCategory.isLoading = false;
      state.emailCategory.isRejected = false;
    },
    [getStatesData.fulfilled]: (state, { payload }) => {
      state.stateData.data = payload?.data;
    },
  },
});
export const { toggleSidebar } = dashboardSlice.actions;
export default dashboardSlice.reducer;
