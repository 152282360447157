import React from "react";
import { Modal, Spin } from "antd";

export default function Spinner() {
  return (
    <>
      <Modal
        centered
        open={true}
        closable={false}
        footer={false}
        className="modalStyle"
      >
        <Spin size="large" />
      </Modal>
    </>
  );
}
