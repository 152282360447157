import "./App.css";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardLayout from "./Layouts/DashboardLayout";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { Toaster } from "react-hot-toast";
import Spinner from "./Components/ui/Spinner";
import { ListRouteElements } from "./Routes/route-elements";

const App = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Provider store={store}>
        <Toaster
          containerStyle={{
            inset: "0px",
          }}
          toastOptions={{
            duration: 3000,
            className: "w-full",
            style: {
              zIndex: 999999,
            },
          }}
        ></Toaster>
        <DashboardLayout>
          <Routes>
            {ListRouteElements.map((item) => (
              <Route key={item.path} path={item.path} element={item.element} />
            ))}
          </Routes>
        </DashboardLayout>
      </Provider>
    </Suspense>
  );
};

export default App;
